<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          v-if="formattedItem.fsalida"
          color="green"
        >
          <v-icon
            dark
          >
            {{ $vuetify.icons.values.presenciaConSalida }}
          </v-icon>
        </v-avatar>
        <v-avatar
          v-else
          color="red"
        >
          <v-icon
            dark
          >
            {{ $vuetify.icons.values.presenciaSinSalida }}
          </v-icon>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { isSameDay } from '@/utils/date'
import { nonEmpty } from '@/utils/templates'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.fechaEntrada = `${this.$options.filters.shortDateTime(item.fentrada)}`
      if (item.fsalida) {
        if (isSameDay(item.fentrada, item.fsalida)) {
          item.fechaSalida = `${this.$options.filters.shortTime(item.fsalida)}`
        } else {
          item.fechaSalida = `${this.$options.filters.shortDateTime(item.fsalida)}`
        }
        item.title = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.fechaEntrada} hasta ${item.fechaSalida}`)
      } else {
        item.title = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.fechaEntrada} - <span class="red--text">SIN SALIDA</span>`)
      }
      item.subtitle = this.$options.filters.linebreaksBr(
        nonEmpty`${item.observaciones_entrada} ${item.observaciones_salida}`
      )
      return item
    }
  }
}
</script>
